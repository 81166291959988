import { graphql } from "gatsby";
import Location from "./Location";
import React from "react";

export const query = graphql`
    query($language: String!, $id: ID!, $customLangKey: AEPSYCMS_I18NLocaleCode!) {
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            location(documentId: $id, locale: $customLangKey) {
                locale
                        slug
                        name
                        header {
                            id
                            title
                            ctaUrl
                            ctaLabel
                            images {
                                media
                                src {
                                    url
                                    urlSharp {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 1400
                                            ) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        onlineOrOnSite {
                            component {
                                ... on AEPSYCMS_ComponentCommonTherapyOrCoach {
                                    title
                                    cards {
                                        id
                                        title
                                        content
                                        icon
                                    }
                                }
                            }
                        }
                        usp {
                            publishedAt
                            component {
                                ...ChapterUsp
                            }
                        }
                        seo {
                            title
                            description
                        }
                        providerQuoteSecond {
                            badgeLabel
                            name
                            quote
                            yearsOfExp
                        }
                        providerQuoteFirst {
                            badgeLabel
                            name
                            quote
                            yearsOfExp
                            avatar {
                                src {
                                    url
                                    urlSharp {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 150
            
                                                webpQuality: 90
                                            ) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                                media
                            }
                        }
                        relatedContent {
                            title
                            components {
                                component {
                                    ... on AEPSYCMS_ComponentAtomsImageButton {
                                        button {
                                            label
                                            to
                                        }
                                        images {
                                            media
                                            src {
                                                url
                                                urlSharp {
                                                    childImageSharp {
                                                        fluid(
                                                            maxWidth: 250
                        
                                                            webpQuality: 90
                                                            fit: CONTAIN
                                                        ) {
                                                            ...GatsbyImageSharpFluid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        primaryCta {
                            label
                            to
                        }
                        explanation {
                           component {
                                ... on AEPSYCMS_ComponentCommonCardInfoList {
                                    title
                                    content
                                    image {
                                        url
                                        urlSharp {
                                            childImageSharp {
                                                fluid(
                                                    maxWidth: 500
                
                                                ) {
                                                    ...GatsbyImageSharpFluid
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        providersSlider {
                            title
                            subtitle
                            quoteType
                            area
                        }
            }
        }
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyApiDe {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    avatarSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 150
                                webpQuality: 90
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiEn {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    avatarSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 150
                                webpQuality: 90
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiFr {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    avatarSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 150
                                webpQuality: 90
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiIt {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    avatarSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 150
                                webpQuality: 90
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
    }
`;

const LocationTherapyZurich: React.FC<any> = (data) => {
	return <Location {...data} />;
};
export default LocationTherapyZurich;
